<template>
    <div class="browser-item" :class="[ theme, standalone? 'view-' + view : '', { 'item-to-delete': isFlaggedToDelete}]"
         @click="onClick" @dblclick="onDblclick" v-touch:longtap="onLongTap" @contextmenu.prevent="onRightClick">
        <drag @drag="onItemDragged" @dragend="onItemDragEnd" :transfer-data="item" :draggable="isDraggable">
            <template v-if="view === 'list' || view === 'large-list'">
                <slot name="list-item" :item="item" :view="view">
                    <e-browser-icon :src="thumbnail" :icon="icon" :item="item"></e-browser-icon>
                    <div class="fields">
                        <div v-if="!fields" class="field name">
                            <slot name="name" :item="item">{{ getName }}</slot>
                        </div>
                        <template v-else>
                            <div v-for="field in visibleFields" class="field" :style="field.style">
                                <slot :name="getSlotName(field)" :item="item" :data="item[field.key]">
                                    <template v-if="field.key.indexOf('name') === 0">
                                        {{ getName }}
                                    </template>
                                    <template v-else-if="field.format === `glyph`">
                                        <i class="fas fa-fw" :class="getNestedValue(item, field.key)"/>
                                    </template>
                                    <template v-else>
                                        {{ BrowserHelper.getFormattedValue(item, field) }}
                                    </template>
                                </slot>
                            </div>
                        </template>
                    </div>
                    <slot name="item-status" :item="item"></slot>
                    <slot name="list-status" :item="item"></slot>
                </slot>
            </template>
            <template v-else>
                <slot name="item" :item="item" :view="view">
                    <e-browser-icon :src="thumbnail" :icon="icon" :item="item"></e-browser-icon>
                    <div class="name">
                        <slot name="name" :item="item">{{ getName }}</slot>
                    </div>
                    <slot name="item-status" :item="item"></slot>
                    <slot name="thumbnail-status" :item="item"></slot>
                </slot>
            </template>
        </drag>
    </div>
</template>

<script>
import BrowserHelper from "../helpers/BrowserHelper.js";
import {getNestedValue} from "../helpers/utils.js";
import EBrowserIcon from "./e-browser-icon.vue";
import {Drag} from "vue-drag-drop"

// e-browser-item
export default {
    name: `e-browser-item`,
    components: {EBrowserIcon, Drag},
    props: {
        item: {type: Object},
        view: {type: String},
        icon: {type: [String, Function]},
        thumbnail: {type: [String, Function]},
        fields: {type: Array},
        draggable: {type: Boolean},
        standalone: {type: Boolean},
        category: {type: Boolean},
        theme: {type: String},
        nameField: {
            type: String,
            default: `name`
        },
        translate: {type: Boolean}
    },
    data() {
        return {
            getNestedValue,
            BrowserHelper
        }
    },
    computed: {
        visibleFields() {
            if (this.fields) {
                return this.fields.filter(f => !f.hidden && !f.hiddenBrowser);
            } else {
                return this.fields;
            }
        },
        isFlaggedToDelete() {
            return this.item && this.item.flag === `to-delete`;
        },
        getName() {
            const name = this.item[this.nameField];
            if (isStringEmpty(name)) {
                return this.tr(`No name`, `Sans nom`);
            } else if (this.translate) {
                return this.tr(name);
            } else {
                return name;
            }
        },
        isDraggable() {
            return !this.isFolder && this.draggable === true;
        }
    },
    methods: {
        getSlotName(field) {
            if (field.slotName) {
                return field.slotName;
            } else {
                return `field_${field.key}`;
            }
        },
        onClick(event) {
            this.onItemSelected(event);
            if (this.isMobileLayout) {
                this.$emit(`right-click`, event, this.item);
            } else {
                this.$emit(`click`, this.item, event);
            }
        },
        onDblclick(event) {
            this.onItemOpened(event);
            this.$emit(`dblclick`, this.item, event);
        },
        onLongTap(event) {
            this.onItemOpened(event);
            this.$emit(`longtap`, this.item, event);
        },
        onItemSelected(event) {
            this.$emit(`item-selected`, this.item, event);
        },
        onItemOpened(event) {
            this.$emit(`open-item`, this.item, event);
        },
        onItemDragged(item, event) {
            this.$emit(`item-dragged`, this.item, event);
        },
        onItemDragEnd(item, event) {
            this.$emit(`item-drag-end`, this.item, event);
        },
        onRightClick(event) {
            this.$emit(`item-selected`, this.item);
            this.$emit(`right-click`, event, this.item);
        }
    }
}
</script>

<style lang="scss" scoped>
.browser-item {
    width: 185px;
    height: 150px;
    text-align: center;
    display: inline-block;
    position: relative;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: background 150ms, transform 150ms;

    ::v-deep .icon {
        position: absolute;
        left: 5px;
        top: 5px;
        right: 5px;
        bottom: 40px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("https://eyeinlivestorage.blob.core.windows.net/public/icons/ui/cursor-help.png");

        &.avatar {
            border-radius: 50%;
            max-width: 96px;
            margin: 0 auto;
            object-fit: cover;
            background-size: cover;
        }
    }

    ::v-deep .image {
        position: absolute;
        left: 5px;
        top: 5px;
        right: 5px;
        bottom: 40px;
        border-radius: 3px;
        overflow: hidden;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        object-fit: contain;
        width: calc(100% - 10px);
        height: calc(100% - 50px);

        &.avatar {
            border-radius: 50%;
            max-width: 96px;
            margin: 0 auto;
        }
    }

    ::v-deep .image,
    ::v-deep .icon {
        > .fas {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 3px;
            font-size: 24px;
            background: rgba(255, 255, 255, 0.9);
        }
    }

    ::v-deep .contain {
        &img,
        &.image {
            object-fit: contain;
        }

        &div,
        &.icon {
            background-size: contain;
        }
    }

    ::v-deep .badge.status {
        position: absolute;
        top: 5px;
        left: 5px;
    }

    ::v-deep .badge {
        &.floating-top-left {
            position: absolute;
            top: 5px;
            left: 5px;
        }
        &.floating-top-right {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }

    ::v-deep .image {
        > .fas {
            &.bottom-right {
                padding: 10px 5px 5px 10px;
                border-bottom-right-radius: inherit;
                border-top-left-radius: 30px;
            }

            &.bottom-left {
                right: auto;
                left: 0;
                padding: 10px 10px 5px 5px;
                border-bottom-left-radius: inherit;
                border-top-right-radius: 30px;
            }

            &.top-right {
                bottom: auto;
                top: 0;
                padding: 5px 5px 10px 10px;
                border-top-right-radius: inherit;
                border-bottom-left-radius: 30px;
            }
        }
    }

    ::v-deep .icon {
        .fas {
            padding: 3px;
            font-size: 20px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            line-height: 24px;
            text-align: center;
        }
    }

    ::v-deep .name {
        display: block;
        position: absolute;
        bottom: 3px;
        left: 0;
        right: 0;
        height: 38px;
        overflow: hidden;
    }

    &.selected,
    &:active {
        color: white;
        background: linear-gradient(to bottom, #5cd360, #00ad9d);
        border-color: #ffffff;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        transform: scale(0.96);
    }

    &:hover {
        color: white;
        background: linear-gradient(to bottom, #4CAF50, #009688);
        border-color: #ffffff;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        transform: scale(1.04);
    }

    &.item-to-delete {
        .name {
            color: var(--color-danger)
        }
    }
}

.view-icons,
.view-large-icons,
.view-small-icons {

}

.view-small-icons {
    font-size: 9pt;

    &.browser-item,
    .browser-item {
        width: 130px;
        height: 110px;

        .icon-type {
            max-height: 20px;
            top: 0;
            right: 0;
        }

        ::v-deep .icon.avatar,
        ::v-deep .image.avatar {
            max-width: 46px;
        }
    }
}

.view-large-icons {
    &.browser-item,
    .browser-item {
        width: 250px;
        height: 250px;

        ::v-deep .icon.avatar,
        ::v-deep .image.avatar {
            max-width: 196px;
        }
    }
}

.view-large-list,
.view-list {
    &.browser-item,
    .browser-item {
        width: 100%;
        height: 40px;
        text-align: left;

        border-width: 1px;
        border-bottom-color: #eee;

        ::v-deep {
            .badge {
                z-index: 1;

                &.floating-top-left,
                &.floating-top-right {
                    display: none;
                }
            }

            .image,
            .icon {
                left: 3px;
                top: 0;
                bottom: 0;
                right: inherit;
                width: 40px;
            }

            .b-avatar {
                margin: 2px;
            }

            .icon.avatar,
            .image.avatar {
                max-width: 40px;
            }

            .image {
                left: 0;
                width: 45px;
                height: 100%;
            }

            .name {
                left: 45px;
                top: 0;
                display: flex;
                align-items: center;
            }

            .fields {
                display: flex;
                position: absolute;
                top: 0;
                left: 55px;
                right: 0;
                align-items: center;
                height: 100%;

                .field {
                    padding: 0 7px;
                    flex-grow: 1;
                    flex-basis: 0;
                    word-break: break-word;
                    overflow: hidden;
                    max-height: calc(100% + 7px);

                    &.size-2 {
                        flex-grow: 2;
                    }

                    &.size-3 {
                        flex-grow: 3;
                    }

                    &.size-4 {
                        flex-grow: 4;
                    }

                    &.size-5 {
                        flex-grow: 5;
                    }
                }
            }
        }

        &.selected,
        &:hover {
            transform: scale(1);
        }

        &:active {
            transform: scale(0.99);
        }
    }

    &.translucent {
        .browser-item {
            border-bottom-color: rgba(255, 255, 255, 0.2);

            &.selected,
            &:active,
            &:hover {
                border-color: rgba(255, 255, 255, 0.2);
            }
        }
    }
}

.view-large-list {
    &.browser-item,
    .browser-item {
        height: 60px;

        ::v-deep {
            .thumbnail {
                width: 80px;
            }

            .image,
            .icon {
                width: 70px;
            }

            .b-avatar {
                margin: 2px;
            }

            .icon.avatar,
            .image.avatar {
                max-width: 50px;
                height: 50px;
            }

            .name {
                left: 85px;
            }

            .fields {
                top: 5px;
                height: 42px;
                left: 85px;
                right: 0;

                .field {
                    max-height: calc(100% + 15px);
                }
            }
        }
    }
}
</style>
