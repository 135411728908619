<template>
    <div class="vue-browser-icon">
        <b-img v-if="type === `image`" class="image" :class="className" :src="getImageSrc"></b-img>
        <div v-else class="icon" :class="className" :style="style"></div>
    </div>
</template>

<script>
    export default {
        name: `e-browser-icon`,
        props: {
            src: { type: [String, Function] },
            item: [Object],
            icon: [String, Function],
            contain: { type: Boolean, default: false }
        },
        computed: {
            className() {
                if (this.contain) {
                    return `contain`;
                } else {
                    return ``;
                }
            },
            type() {
                if (this.getImageSrc) {
                    return `image`;
                } else {
                    return `icon`
                }
            },
            getImageSrc() {
                if (typeof this.src === `function`) {
                    return this.src(this.item);
                } else {
                    return this.src;
                }
            },
            style() {
                if (this.icon) {
                    if (typeof this.icon === `function`) {
                        return {
                            'background-image': `url("${this.icon(this.item)}")`
                        };
                    } else if (this.icon.indexOf(`.png`) > -1) {
                        return {
                            'background-image': `url("${this.icon}")`
                        };
                    } else {
                        return {
                            'background-image': `url("${this.getPublicIcon(this.icon)}")`
                        };
                    }
                } else {
                    return {};
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
