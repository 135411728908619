<template>
    <b-modal v-model="visibilityComputed" @hide="close" scrollable size="xl" :title="'Media Library|Bibliothèque multimédia'|tr" modal-class="e-file-library" :hide-footer="activeProperties !== null || tabIndex > 0" body-bg-variant="white">
        <b-tabs class="tabs-pills" v-model="tabIndex">
            <template v-if="!activeProperties">
                <b-tab :title="'Library|Bibliothèque'|tr" class="pt-3">
                    <e-browser v-model="fileSelected" :items="libraryFiltered" :fields="fields" @item-opened="onItemOpened" @item-dropped="onFileDropped"
                               title="Library|Bibliothèque" thumbnail="thumbnail" :translucent="false" header-variant="light" :key="1"
                               delete-or-restore-url="/library/files/delete-or-restore" :api-options="{system_api: true}" context-auto show-trash enable-trash>
                        <template #actions>
                            <b-input-group class="d-inline-flex w-auto ml-2" style="vertical-align: middle">
                                <b-input-group-text><i class="fas fa-folder"/></b-input-group-text>
                                <b-select v-model="filters.source">
                                    <option value="all" v-tr>All|Tous</option>
                                    <option value="eye-intelligence" v-tr>Eye-Intelligence</option>
                                    <option value="map" v-tr>Map Photos|Photos de plans</option>
                                    <option value="map-virtual-visit" v-tr>Map Virtual Visit|Plan Visite Virtuelle</option>
                                    <option value="digital-signage" v-tr>Digital Signage|Affichage numérique</option>
                                    <option value="cms-website" v-tr>CMS - Website|CMS - Site internet</option>
                                    <option value="cms-items" v-tr>CMS - Items|CMS - Éléments</option>
                                </b-select>
                            </b-input-group>

                            <b-select class="d-inline-flex w-auto ml-2" v-model="filters.type">
                                <option value="all" v-tr>All|Tous</option>
                                <option value="docs" v-tr>Documents</option>
                                <option value="image" v-tr>Images</option>
                                <option value="image-360" v-tr>360° Images|Images 360°</option>
                                <option value="icon" v-tr>Icon|Icône</option>
                                <option value="logo" v-tr>Logos</option>
                                <option value="avatar" v-tr>Avatars</option>
                                <option value="favicon" v-tr>Favicon</option>
                            </b-select>
                        </template>
                        <template #context="{item}">
                            <template v-if="item">
                                <b-dropdown-item v-if="item.category === 'image-360'" @click="imageToPreview = item; image360PreviewVisible = true">
                                    <i class="fas fa-cube"/>
                                    <span v-tr >Preview 360|Aperçu 360</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-else :href="item.url" target="_blank">
                                    <i class="fas fa-magnifying-glass"/>
                                    <span v-tr>Preview|Aperçu</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="renameModal = true">
                                    <i class="fas fa-pen-field"/> <span v-tr>Rename|Renommer</span>
                                </b-dropdown-item>
                                <b-dropdown-divider/>
                            </template>
                        </template>
                        <template #field_size="{data}">
                            {{data|fileSize}}
                        </template>
                    </e-browser>
                    <e-iframe-dialog
                        v-if="image360PreviewVisible" v-model="image360PreviewVisible" preset="image-360-preview"
                        :url="`https://storage.googleapis.com/vrview/2.0/index.html?default_yaw=${yawCorrection}&preview=${imageToPreview.thumbnail}&image=${imageToPreview.url}&is_autopan_off=true`"
                        :item-id="imageToPreview.id" :item-type="imageToPreview.type" :dealer-id="imageToPreview.dealerid">
                        <template #header-actions>
                            <b-row class="yawSliderContainer">
                                <b-col sm="9">
                                    <b-input v-model="yawCorrection" type="range" min="-180" max="180" step="1" debounce="100" class="yawSlider"></b-input>
                                </b-col>
                                <b-col sm="3">
                                    <b-form-input v-model="yawCorrection" :value="yawCorrection" type="number" size="sm" min="-180" max="180" class="yawInput"></b-form-input>
                                </b-col>
                            </b-row>
                        </template>
                    </e-iframe-dialog>
                    <b-modal
                        v-if="renameModal && fileSelected" v-model="renameModal"
                        size="xs" :title="'Rename|Renommer'|tr" modal-class="e-file-library" body-bg-variant="white"
                        :ok-title="`Rename|Renommer`|tr" :cancel-title="`Cancel|Annuler`|tr"
                        @ok="renameItem" @cancel="renameModal = false"
                        @show="renameValue = fileSelected.name">
                        <b-form-group :label="tr(`Enter the new name for '|Entrez le nouveau nom pour '`) + fileSelected.name + `'`">
                            <e-input v-model="renameValue" trim/>
                        </b-form-group>
                    </b-modal>
                </b-tab>
                <b-tab :title="'Import|Importer'|tr" class="pt-3">
                    <e-file-input ref="file" type="image" theme="light" @change="onNewUpload"/>
                    <hr>
                    <b-form-group :label="'Import from URL|Importer depuis une URL'|tr">
                        <b-input-group>
                            <b-input-group-text><i class="fas fa-globe"/></b-input-group-text>
                            <e-input type="url" v-model="customUrl" maxlength="2500" placeholder="Ex: https://image.eye-in.com"/>
                            <b-btn @click="selectFile(customUrl)" variant="primary">
                                <span v-tr>Import|Importer</span>
                            </b-btn>
                        </b-input-group>
                    </b-form-group>
                </b-tab>
            </template>
            <b-tab v-if="activeProperties && !isImportMultiple" :title="(newUpload ? 'Import New File|Importer un nouveau fichier' : 'Properties|Propriétés')|tr" class="pt-3">
                <b-media>
                    <template #aside>
                        <div v-if="loading" class="d-flex align-items-center justify-content-center" style="width: 200px; height: 200px">
                            <i class="fa-solid fa-spinner fa-spin-pulse"></i>
                        </div>
                        <b-img v-else-if="newUpload" width="200" height="200" style="object-fit: contain" rounded :src="activeProperties.thumbnail"/>
                        <e-file-input
                            v-else
                            :value="properties.file"
                            :default-image="properties.thumbnail"  :image-style="{ width: '200px', height: '200px' }"
                            contain type="image" theme="light" @change="onUpdateFile"/>
                    </template>
                    <b-form-group label-cols-md="3" :label="'Name|Nom'|tr">
                        <b-input-group>
                            <e-input v-model="activeProperties.name"/>
                            <b-input-group-text>{{activeProperties.extension}}</b-input-group-text>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label-cols-md="3" :label="'Description'|tr">
                        <b-textarea v-model="activeProperties.description"/>
                    </b-form-group>
                    <b-form-group label-cols-md="3" :label="'Category|Catégorie'|tr">
                        <b-select v-model="activeProperties.category">
                            <option value="docs" v-tr>Documents</option>
                            <option value="image" v-tr>Images</option>
                            <option value="image-360" v-tr>360° Images|Images 360°</option>
                            <option value="icon" v-tr>Icon|Icône</option>
                            <option value="logo" v-tr>Logos</option>
                            <option value="avatar" v-tr>Avatars</option>
                            <option value="favicon" v-tr>Favicon</option>
                            <option value="default" v-tr>Other|Autre</option>
                        </b-select>
                    </b-form-group>
                    <b-form-group v-if="newUpload">
                        <b-checkbox v-model="newUpload.showInLibrary">
                            <span v-tr>Add to the Library|Ajouter dans la bibliothèque</span>
                        </b-checkbox>
                    </b-form-group>
                    <b-form-group label-cols-md="3" :label="'Meta'|tr">
                        {{activeProperties.size|unitBits}}
                        <br>
                        {{activeProperties.width}} x {{activeProperties.height}}px
                    </b-form-group>
                    <b-form-group v-if="activeProperties.dimensions && activeProperties.category !== 'image-360'" label-cols-md="3" label="Dimensions">
                        <b-select v-model="activeProperties.dimensions" :options="dimensionsOptions"></b-select>
                    </b-form-group>
                    <b-form-group v-if="activeProperties.category === 'image-360'" label-cols-md="3" label="Offset angle">
                        <b-row>
                            <b-col sm="3">
                                <b-form-input v-model="activeProperties.offsetAngle" placeholder="0" type="number" min="-180" max="180" step="1"></b-form-input>
                            </b-col>
                            <b-col sm="6" v-if="activeProperties.url">
                                <b-btn @click="imageToPreview = activeProperties; open360ImageEditor()" variant="outline-primary">
                                    <span v-tr>Open 360° Editor|Ouvrir l'éditeur 360°</span>
                                </b-btn>
                            </b-col>
                        </b-row>
                        <b-modal
                            v-if="image360PreviewVisible" v-model="image360PreviewVisible"
                            size="xl" :title="'360° Offset|Décalage 360°'|tr" modal-class="e-file-library" body-bg-variant="white"
                            @ok="activeProperties.offsetAngle = parseInt(yawCorrection)">
                            <div id="vrview" style="width: 100%; height: 500px; pointer-events: none; user-select: none"></div>
                            <b-row class="yawSliderContainer">
                                <b-col sm="9">
                                    <b-input v-model="yawCorrection" type="range" min="-180" max="180" step="1" @change="update360ImageYaw" class="yawSlider"></b-input>
                                </b-col>
                                <b-col sm="3">
                                    <b-input v-model="yawCorrection" type="number" size="sm" min="-180" max="180" @update="update360ImageYaw" debounce="500" class="yawInput"></b-input>
                                </b-col>
                            </b-row>
                        </b-modal>
                    </b-form-group>
                </b-media>
                <div class="w-100 text-right pt-2">
                    <b-btn variant="light" class="mr-2" @click="resetUpload">
                        <span v-tr>Cancel|Annuler</span>
                    </b-btn>
                    <b-btn variant="primary" @click="newUpload ? upload(newUpload) : update()" :disabled="loading">
                        <i v-if="loading" class="fa-solid fa-spinner fa-spin-pulse mr-1"></i>
                        <span v-if="newUpload" v-tr>Upload|Téléverser</span>
                        <span v-else v-tr>Save|Enregistrer</span>
                    </b-btn>
                </div>
            </b-tab>
            <b-tab v-else :title="`Import List|Liste d'importation`|tr" class="pt-3">
                <e-browser v-model="importSelected" :items="filesToUpload" :fields="multipleImportFields" :key="2"
                           title="Library|Bibliothèque" thumbnail="thumbnail" :translucent="false" header-variant="light"
                           noActions noOptions forced-view="list" class="overflow-auto" style="max-height: 400px"
                           @item-opened="previewImage(importSelected.name, importSelected.thumbnailLarge)" @item-dropped="onFilesDropped">
                    <template #context="{item}">
                        <template v-if="item">
                            <b-dropdown-item @click="previewImage(item.name, item.thumbnailLarge)">
                                <i class="fas fa-magnifying-glass"/>
                                <span v-tr>Preview|Aperçu</span>
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item variant="danger" @click="removeFromImportList(item)">
                                <i class="fas fa-trash"/>
                                <span v-tr>Remove|Supprimer</span>
                            </b-dropdown-item>
                        </template>
                    </template>
                    <template #field_size="{data}">
                        {{data|fileSize}}
                    </template>
                    <template #item-count>
                        <div class="d-flex">
                            <span class="f-500 text-nowrap">{{ filesToUpload.length }} / {{ pendingFiles.length }} <span v-tr>items|éléments </span></span>
                            <div class="w-100 text-center d-inline-block">
                                <i v-if="!isMultipleFilesPendingFinished" class=" fa-solid fa-spinner fa-spin-pulse"></i>
                            </div>
                        </div>
                    </template>
                </e-browser>
                <div class="w-100 text-right pt-2">
                    <b-btn variant="light" class="mr-2" @click="resetUpload">
                        <span v-tr>Cancel|Annuler</span>
                    </b-btn>
                    <b-btn variant="primary" @click="multipleUpload" :disabled="!isMultipleFilesPendingFinished || filesToUpload.length === 0">
                        <i v-if="!isMultipleFilesPendingFinished" class="fa-solid fa-spinner fa-spin-pulse mr-1"></i>
                        <span v-tr>Upload {{filesToUpload.length}} files|Télécharger les {{filesToUpload.length}} fichiers</span>
                    </b-btn>
                </div>
            </b-tab>
        </b-tabs>

        <template v-if="!activeProperties && tabIndex === 0" #modal-footer>
            <div class="text-right mt-3">
                <b-btn variant="light" class="mr-2" @click="close">
                    <span v-tr>Cancel|Annuler</span>
                </b-btn>
                <b-btn @click="selectFile(fileSelected)" :disabled="!fileSelected" variant="primary"><span v-tr>Select|Sélectionner</span></b-btn>
            </div>
        </template>
    </b-modal>
</template>

<script>
import swal from "sweetalert";
import EBrowser from "./e-browser.vue";
import EFileInput from "./e-file-input.vue";
import EInput from "./e-input.vue";
import FileHelper from "../helpers/FileHelper.js";
import Network from "../helpers/Network.js";
import EIframeDialog from "./e-iframe-dialog.vue";

// e-file-library #hot-reload-debug
    export default {
        name: `e-file-library`,
        components: {EIframeDialog, EBrowser, EFileInput, EInput},
        props: {
            value: {},
            file: {},
            type: { type: String, default: `image` },
            category: { type: String, default: `default` }, // default|doc|image|icon|logo|avatars|favicon|image-360
            returnObject: { type: Boolean },
            returnMetaInfo: { type: Boolean },
            allowAll: { type: Boolean },
            visible: { type: Boolean },
            defaultSrc: { type: String },
            source: {type: String, default: `eye-intelligence`}, // to separate resources
            sourceId: {},
            maxResolution: {type: Number, default: 2048},
            maxImageSize: {type: Number, default: 4096}, // 4Mo
            maxVideoSize: {type: Number, default: 15000} // 15Mo
        },
        data() {
            return {
                loading: false,
                tabIndex: 0,
                customUrl: ``,
                newUpload: null,
                filesToUpload: [],
                pendingFiles: [],
                image360PreviewVisible: false,
                imageToPreview: null,
                vrViewPlayer: null,
                yawCorrection: `0`,
                openProperties: false,
                renameModal: false,
                fileSelected: null,
                importSelected: null,
                properties: null,
                renameValue: ``,
                dimensionsSelected: `Normal`,
                filters: {
                    type: `all`,
                    source: `all`
                },
                library: [],
                fields: [
                    {key: `name`, label: this.tr(`Name|Nom`), sortable: true},
                    {key: `dealerid`, label: this.tr(`Venue|Compte`), hidden: true, style: { 'max-width': `100px` }},
                    {key: `type`, label: this.tr(`Type|Type`), sortable: true, style: { 'max-width': `80px` }},
                    {key: `duration`, label: this.tr(`Duration|Durée`), sortable: true, style: { 'max-width': `100px` }},
                    {key: `width`, label: this.tr(`Width|Largeur`), sortable: true, style: { 'max-width': `100px` }},
                    {key: `height`, label: this.tr(`Height|Hauteur`), sortable: true, style: { 'max-width': `100px` }},
                    {key: `size`, label: this.tr(`Size|Taille`), sortable: true, style: { 'max-width': `100px` }},
                    {key: `dateCreation`, label: this.tr(`Creation|Création`), sortable: true, hidden: true, style: { 'max-width': `130px` }},
                    {key: `dateEdition`, label: this.tr(`Last edition|Modification`), sortable: true, style: { 'max-width': `130px` }},
                    {key: `editedBy`, label: this.tr(`Edited By|Modifié par`), sortable: true, style: { 'max-width': `130px` }}
                ],
                multipleImportFields: [
                    {key: `name`, label: this.tr(`Name|Nom`), sortable: true},
                    {key: `type`, label: this.tr(`Type|Type`), sortable: true, style: { 'max-width': `80px` }},
                    {key: `category`, label: this.tr(`Category|Categorie`), sortable: true, style: { 'max-width': `120px` }},
                    {key: `extension`, label: this.tr(`Extension|Extension`), sortable: true, style: { 'max-width': `120px` }},
                    {key: `width`, label: this.tr(`Width|Largeur`), sortable: true, style: { 'max-width': `100px` }},
                    {key: `height`, label: this.tr(`Height|Hauteur`), sortable: true, style: { 'max-width': `100px` }},
                    {key: `size`, label: this.tr(`Size|Taille`), sortable: true, style: { 'max-width': `100px` }}
                ]
            }
        },
        computed: {
            activeProperties() {
                return this.newUpload || this.properties
            },
            visibilityComputed: {
                get() {
                    return this.visible;
                },
                set(value) {
                    this.$emit(`update:visible`, value);
                }
            },
            internalValue: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit(`input`, value);
                }
            },
            dimensionsOptions() {
                const { width, height } = this.activeProperties;
                const createOption = (scale, label) => ({
                    value: { width: Math.round(width * scale), height: Math.round(height * scale) },
                    text: `${label} (${Math.round(width * scale)} x ${Math.round(height * scale)}) px`
                });

                return [
                    { value: null, text: `Normal (${width} x ${height}) px` },
                    createOption(0.7, `70%`),
                    createOption(0.5, `50%`),
                    createOption(0.3, `30%`)
                ];
            },
            libraryFiltered() {
                if (this.filters.type === `all` && this.filters.source === `all`) {
                    return this.library;
                } else {
                    return this.library.filter(f => {
                        if (f.category !== this.filters.type && this.filters.type !== `all`) {
                            return false;
                        }
                        if (f.source !== this.filters.source && this.filters.source !== `all`) {
                            return false;
                        }
                        return true;
                    });
                }
            },
            isImportMultiple() {
                return this.pendingFiles?.length > 0 || this.filesToUpload?.length > 0;
            },
            isMultipleFilesPendingFinished() {
                return this.filesToUpload?.length === this.pendingFiles?.length;
            }
        },
        created() {
            this.getLibrary();
            if (this.category === `default`) {
                this.filters.type = `all`;
            } else {
                this.filters.type = this.category;
            }
            this.filters.source = this.source || `all`;
        },
        methods: {
            getLibrary() {
                Network.get(`/library/files/list/${this.dealerid}`, null, {system_api: true})
                    .then(resp => {
                        this.library = resp.data;
                    });
            },
            resetUpload() {
                if (this.$refs.file) {
                    this.$refs.file.reset();
                }
                this.pendingFiles = [];
                this.filesToUpload = [];
                this.newUpload = null;
                this.properties = null;
                requestAnimationFrame(() => {
                    this.tabIndex = 0;
                });
            },
            reduceFile(file, onlyMetadata = false) {
                if (onlyMetadata) {
                    return {
                        id: file.id,
                        name: file.name,
                        category: file.category,
                        url: file.url,
                        offsetAngle: file.offsetAngle,
                        revision: file.revision
                    }
                }
                return {
                    id: file.id,
                    name: file.name,
                    extension: file.extension,
                    size: file.size,
                    type: file.type,
                    thumbnail: file.thumbnail,
                    thumbnailLarge: file.thumbnailLarge,
                    url: file.url,
                    offsetAngle: file.offsetAngle
                }
            },
            selectFile(file) {
                if (this.returnMetaInfo || this.returnObject) {
                    const returnObject = this.reduceFile(file, this.returnMetaInfo);
                    this.$emit(`input`, returnObject);
                    this.$emit(`selected`, returnObject);
                } else {
                    this.$emit(`input`, file.url);
                    this.$emit(`selected`, file.url);
                }
                this.$emit(`update:file`, file);
                this.close();
            },
            show() {
                this.visibilityComputed = true;
                this.$emit(`show`);
            },
            close() {
                this.visibilityComputed = false;
                this.$emit(`close`);
            },
            onFileDropped(item, event) {
                event.preventDefault();
                const file = event.dataTransfer.files[0];
                this.onNewUpload(file);
            },
            onFilesDropped(item, event) {
                event.preventDefault();
                const files = [...event.dataTransfer.files];
                this.pendingFiles.push(...files);
                for (let index = 0; index < files.length; index++) {
                    this.onNewUpload(files[index])
                        .then(() => {
                            this.filesToUpload.push(this.newUpload);
                        })
                        .catch(() => {
                            this.showErrorMessage();
                        })
                }
            },
            renameItem() {
                if (this.renameValue === this.fileSelected.name) {
                    this.renameModal = false;
                    return;
                }
                Network.patch(`/library/files/rename`, {
                    id: this.fileSelected.id,
                    newName: this.renameValue
                }, { system_api: true })
                    .then(() => {
                        this.fileSelected.name = this.renameValue;
                        this.$forceUpdate();
                    }).catch(() => {
                    this.showErrorMessage();
                });
            },
            onItemOpened(event) {
                this.properties = JSON.parse(JSON.stringify(this.fileSelected || this.importSelected));
                this.openProperties = true;
            },
            onNextUpload() {
                this.upload(this.newUpload);
            },
            onNewUpload(file) {
                if (!file) {
                    return Promise.reject();
                }
                const newUpload = {
                    dealerid: this.dealerid,
                    source: this.source,
                    sourceId: this.sourceId,
                    file: file,
                    name: FileHelper.getFileName(file.name),
                    type: FileHelper.getFileType(file.name),
                    extension: FileHelper.getFileExtension(file.name),
                    size: file.size,
                    showInLibrary: true,
                    category: this.category,
                    dimensions: null
                }

                return this.generateThumbnail(newUpload)
                    .then(() => {
                        this.newUpload = newUpload;
                    })
                    .catch(() => {
                        this.showErrorMessage();
                    });
            },
            generateThumbnail(file) {
                return new Promise((resolve, reject) => {
                    this.loading = true;

                    const handleImage = (image) => {
                        file.width = image.width;
                        file.height = image.height;

                        // Change file category to image-360 if the image width is 5504 or 11008
                        if (file.width === 5504 || file.width === 11008) {
                            file.category = `image-360`;
                        }

                        if (file.category === `image`) {
                            if (file.width > this.maxResolution || file.height > this.maxResolution) {
                                if (file.width > file.height) {
                                    file.height = Math.round(file.height / Math.round(file.width / this.maxResolution));
                                    file.width = this.maxResolution;
                                } else {
                                    file.width = Math.round(file.width / Math.round(file.height / this.maxResolution));
                                    file.height = this.maxResolution;
                                }
                                swal({
                                    icon: `info`,
                                    title: this.tr(`File is Too Big|Le fichier est trop grand`),
                                    text: this.tr(`It will be resized automatically to ${file.width}*${file.height}px.|Il sera redimensionné automatiquement en ${file.width}*${file.height}px.`)
                                });
                            } else if (file.size > this.maxImageSize * 1000) {
                                swal({
                                    icon: `info`,
                                    title: this.tr(`File is Too Heavy|Le fichier est trop lourd`),
                                    text: this.tr(`It will be compressed automatically to load faster. We recommend to use maximum 500KB files.|Il sera compressé automatiquement pour charger plus vite. Nous recommendons de ne pas dépasser 500KB`)
                                });
                            }
                        }

                        if (image.width < 300 && image.height < 300) {
                            this.$set(file, `thumbnail`, FileHelper.createThumbnail(image, Math.max(image.width, image.height)));
                        } else {
                            this.drawThumbnail(file, image);
                            this.drawThumbnail(file, image, true);
                        }
                        this.loading = false;
                        resolve();
                    };

                    switch (file.type) {
                        case `image`:
                        case `image-360`:
                            FileHelper.loadImageFromFile(file.file)
                                .then(handleImage)
                                .catch(reject);
                            break;
                        case `video`:
                            FileHelper.loadVideoFromFile(file, (el) => this.drawThumbnail(file, el))
                                .then(video => {
                                    file.duration = Math.round(video.duration);
                                    file.height = Math.round(video.videoHeight);
                                    file.width = Math.round(video.videoWidth);
                                    this.loading = false;
                                    resolve();
                                })
                                .catch(reject);
                            break;
                        default:
                            this.loading = false;
                            reject();
                    }
                });
            },
            drawThumbnail(file, element, large = false) {
                if (large) {
                    this.$set(file, `thumbnailLarge`, FileHelper.createThumbnail(element, 500));
                } else {
                    this.$set(file, `thumbnail`, FileHelper.createThumbnail(element));
                }
            },
            multipleUpload() {
                const totalFiles = this.filesToUpload.length;
                let uploadedFiles = 0;
                this.showLoading();
                const uploadPromises = this.filesToUpload.map(file => {
                    return Network.post(`/library/files/create`, file, {
                        process_files: true, system_api: true
                    })
                        .then(resp => {
                            this.library.push(resp.data);
                            uploadedFiles++;
                            const percent = (uploadedFiles / totalFiles) * 100;
                            if (percent === 100) {
                                this.hideLoading();
                            } else {
                                this.showLoading(null, null, percent);
                            }
                            console.log(`uploaded: ` + file.name);
                        })
                        .catch(e => {
                            this.showErrorMessage();
                            console.error(e);
                        })
                });

                Promise.all(uploadPromises)
                    .then(() => {
                        this.getLibrary();
                        this.resetUpload();
                    })
                    .catch(error => {
                        console.error(`Error uploading files:`, error);
                        this.showErrorMessage();
                    }).finally(() => {
                        this.hideLoading();
                    });
            },
            upload(file) {
                this.showLoading();
                console.log(`start upload`);
                Network.post(`/library/files/create`, file, {
                    process_files: true, system_api: true
                })
                    .then(resp => {
                        this.library.push(resp.data);
                        console.log(`uploaded: ` + file.name);
                        this.selectFile(resp.data);
                        this.getLibrary()
                        this.resetUpload()
                        console.log(`end upload`);
                        this.hideLoading();
                    })
                    .catch(e => {
                        this.showErrorMessage();
                        console.error(e);
                    })
            },
            update() {
                this.showLoading();

                const isDimensionsChanged = !!this.properties.dimensions;

                if (isDimensionsChanged) {
                    fetch(this.properties.url)
                        .then(response => response.blob())
                        .then(blob => {
                            this.properties.file = new File([blob], this.properties.name, {type: blob.type});
                            this.properties.width = this.properties.dimensions.width;
                            this.properties.height = this.properties.dimensions.height;
                            this.properties.thumbnail = null;
                            this.properties.thumbnailLarge = null;
                            this.generateThumbnail(this.properties).then(() => {
                                this.patchFilesProperties();
                            });
                        })
                        .catch(error => {
                            console.error(`Error fetching the file from URL:`, error);
                            this.showErrorMessage();
                        });
                } else {
                    this.patchFilesProperties();
                }
            },
            removeFromImportList(file) {
                const index = this.filesToUpload.findIndex(item => item.name === file.name);
                if (index !== -1) {
                    this.filesToUpload.splice(index, 1);
                    this.pendingFiles.splice(index, 1);
                }
                if (this.filesToUpload.length === 0) {
                    this.resetUpload();
                }
            },
            patchFilesProperties() {
                const isFileReUpload = !!this.properties.file;

                Network.patch(`/library/files/update`, this.properties, {
                    process_files: isFileReUpload, system_api: true
                })
                    .then((resp) => {
                        const index = this.library.findIndex(item => item.id === resp.data.id);
                        if (index !== -1) {
                            this.library.splice(index, 1, { ...this.library[index], ...resp.data });
                        } else {
                            this.library.push(resp.data);
                        }
                        this.selectFile(resp.data);
                        this.resetUpload()
                        this.hideLoading();
                    })
                    .catch(e => {
                        this.showErrorMessage();
                        console.error(e);
                    })
                    .finally(() => this.properties = null)
            },
            onUpdateFile(file) {
                if (!file) {
                    return;
                }

                if (FileHelper.getFileType(file.name) !== this.properties.type) {
                    return;
                }
                const { id, name, type, category, description } = this.properties;
                this.properties = {
                    id,
                    name,
                    category,
                    description,
                    dealerid: this.dealerid,
                    source: this.source,
                    sourceId: this.sourceId,
                    file: file,
                    type: type,
                    extension: FileHelper.getFileExtension(file.name),
                    size: file.size,
                    showInLibrary: true,
                    dimensions: null
                }
                this.generateThumbnail(this.properties);
            },
            previewImage(title, url) {
                swal({
                    title: title,
                    content: {
                        element: `img`,
                        attributes: {
                            width: `400`,
                            src: url,
                            alt: title
                        }
                    }
                })
            },
            open360ImageEditor() {
                this.image360PreviewVisible = true;
                this.yawCorrection = this.activeProperties.offsetAngle || `0`;
                this.onVrViewLoad();
            },
            onVrViewLoad() {
                const scriptSrc = `https://storage.googleapis.com/vrview/2.0/build/vrview.min.js`;
                const scriptLoaded = document.querySelector(`script[src="${scriptSrc}"]`);

                if (!scriptLoaded) {
                    const script = document.createElement(`script`);
                    script.src = scriptSrc;
                    script.async = true;
                    script.onload = () => {
                        this.initializeVrViewPlayer();
                    };
                    document.head.appendChild(script);
                } else {
                    this.$nextTick(() => requestAnimationFrame(() => this.initializeVrViewPlayer()));
                }
            },
            initializeVrViewPlayer() {
                this.vrViewPlayer = new VRView.Player(`#vrview`, {
                    width: `100%`,
                    height: `100%`,
                    image: this.imageToPreview.url,
                    default_yaw: -this.yawCorrection,
                    is_autopan_off: true,
                    hide_fullscreen_button: true,
                });
                this.vrViewPlayer.on(`ready`, () => {
                    this.vrViewPlayer.addHotspot(`hotspot-one`, {
                        pitch: 0, // In degrees. Up is positive.
                        yaw: -this.yawCorrection, // In degrees. To the right is positive.
                        radius: 0.1, // Radius of the circular target in meters.
                        distance: 1, // Distance of target from camera in meters.
                    });
                });
            },
            update360ImageYaw() {
                if (parseInt(this.yawCorrection) > 180) {
                    this.yawCorrection = `180`;
                } else if (parseInt(this.yawCorrection) < -180) {
                    this.yawCorrection = `-180`;
                }
                this.vrViewPlayer.setContent({
                    image: this.imageToPreview.url,
                    default_yaw: -this.yawCorrection,
                    is_autopan_off: true,
                    is_yaw_only: true,
                    hide_fullscreen_button: true,
                });
                this.$nextTick(() => {
                    this.vrViewPlayer.addHotspot(`hotspot-one`, {
                        pitch: 0, // In degrees. Up is positive.
                        yaw: -this.yawCorrection, // In degrees. To the right is positive.
                        radius:   0.1, // Radius of the circular target in meters.
                        distance: 1, // Distance of target from camera in meters.
                    });
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
.yawSliderContainer {
    position: absolute;
    align-items: end;
    align-self: center;
    top: 0;
    left: calc(50% - 200px);
    background: white;
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 1rem;
    width: 400px;

    .yawSlider::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 18px;
        height: 18px;
        border-radius: 10px;
        background-color: var(--primary);
        overflow: visible;
        cursor: pointer;
    }
}
</style>
